/*
|-----------------------
| 	    data-reel
|-----------------------
|
*/

.data-reel {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 50px;
  width: 100%;
  display: block;
  .sur_titre_dr {
    font-family: Staatliches;
    font-weight: 400;
    font-size: 25px;
    line-height: 70px;
    text-align: center;
    color: #f7a600;
    margin-top: 60px;
  }
  .titre_dr {
    color: #f7a600;
    font-family: Staatliches;
    font-size: 71px;
    line-height: initial;
    text-align: left;
    font-weight: 400;
    margin: 70px 15px 30px 15px;
    @include media-breakpoint-down(md) {
      font-size: 50px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 30px;
      text-align: center;
    }
  }
  .image_dr {
    margin: auto;
    display: block;
  }
  .block_dr {
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom: 30px;
    }
    .lien_dr {
      background: none;
      color: #fff;
      padding: 4px 40px;
      margin: 100px 0 0 0;
      text-transform: uppercase;
      float: right;
      @include media-breakpoint-down(sm) {
        margin: 10px 0 0 0;
        float: initial;
      }
    }
  }
  .mgb-md {
    margin-bottom: 50px;
  }
}

.graph-div {
  color: $white;
  padding-top: 50px;
  height: 400px;
  @include media-breakpoint-down(sm) {
    padding-top: 20px;
    text-align: center;
    height: inherit;
  }

  .graph-icon {
    height: 40px;
    background-position: center left;
    background-repeat: no-repeat;
    @include media-breakpoint-down(sm) {
      background-position: center center;
    }
  }
  .graph-title {
    margin: 15px 0 7px 0;
    padding: 6px 0 5px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    color: #fefefe;
    font-family: Staatliches;
    font-size: 22px;
    font-weight: 400;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
  .graph-intro {
    color: #fefefe;
    font-family: Archivo;
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    position: absolute;
    height: 30px;
    display: block;
    @include media-breakpoint-down(md) {
      font-size: 10px;
      position: relative;
    }
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      left: 15px;
      right: 15px;
    }
    @include media-breakpoint-down(xs) {
      white-space: initial;
      left: 0px;
      right: 0px;
    }
  }
  .graph-intro2 {
    color: #fefefe;
    font-family: Archivo;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 18px;
  }
  .graph-chiffre1 {
    height: 30px;
    margin-top: 26px;
    color: #fefefe;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 700;
    @include media-breakpoint-down(xs) {
      height: initial;
    }
  }
  .graph-chiffre2 {
    color: #fefefe;
    font-family: Staatliches;
    font-size: 45px;
    font-weight: 400;
    position: relative;
    height: 110px;
    margin-top: 10px;
    text-align: right;
    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    &:before {
      content: '+';
      color: #fefefe;
      font-family: Staatliches;
      font-size: 42px;
      font-weight: 400;
      padding-left: 0px;
      padding-top: 12px;
      display: inline-block;
      left: 17px;
      position: absolute;
      @include media-breakpoint-down(md) {
        left: 30px;
        font-size: 38px;
      }
      @include media-breakpoint-down(sm) {
        left: 40px;
      }
      @include media-breakpoint-down(xs) {
        left: -30px;
        right: 0;
      }
    }
    &:after {
      content: '%';
      font-family: Archivo;
      font-size: 25px;
      font-weight: 400;
      vertical-align: super;
      padding-right: 75px;
      position: absolute;
      left: 89px;
      top: 18px;
      @include media-breakpoint-down(md) {
        left: 90px;
      }
      @include media-breakpoint-down(sm) {
        left: 100px;
      }
      @include media-breakpoint-down(xs) {
        left: 0;
        right: -160px;
      }
    }
    .counterUp {
      position: absolute;
      left: 38px;
      top: 6px;
      @include media-breakpoint-down(md) {
        left: 62px;
        top: 16px;
      }
      @include media-breakpoint-down(xs) {
        left: 0;
        right: -27px;
        top: 16px;
      }

    }
    span {
      background-image: url(../img/cercle.png);
      width: 99px;
      height: 99px;
      position: absolute;
      left: 0;
      @include media-breakpoint-down(sm) {
        right: 0;
        margin: auto;
      }
    }
  }
  .graph-date {
    margin-bottom: 8px;
    color: #fefefe;
    font-family: Archivo;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
  }
  .graph-map {
    max-width: 100%;
    margin-bottom: 3px;
  }
  .graph-gauche, .graph-droite {
    position: relative;
    .elem1, .elem4 {
      font-family: Staatliches;
      font-size: 30px;
      font-weight: 400;
      @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .elem2, .elem5 {
      font-family: "Archivo";
      font-size: 10px;
      line-height: 10px;
      font-weight: 400;
    }
    .elem3, .elem6 {
      font-family: "Archivo";
      font-size: 24px;
      font-weight: 700;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  .graph-gauche {
    float: left;
    text-align: right;
    width: 50%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .data-bar {
      float: left;
      width: 47%;
      margin-top: 70px;
      @include media-breakpoint-down(lg) {
        margin-top: 70px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 40px;
        width: 100%;
        text-align: center;
      }
    }
    .graph-bar {
      float: right;
      width: 47%;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 90px;
        margin: auto;
        float: none;
      }
    }
  }
  .graph-droite {
    float: right;
    text-align: left;
    width: 47%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .data-bar {
      float: right;
      width: 47%;
      margin-top: 70px;
      @include media-breakpoint-down(lg) {
        margin-top: 70px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 40px;
      }
      @include media-breakpoint-down(sm) {
        float: left;
        width: 100%;
        margin-top: 0px;
        text-align: center;
      }
    }
    .graph-bar {
      float: left;
      width: 47%;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 90px;
        margin: auto;
        float: none;
      }
    }
  }
  .graph-bar {
    .chiffre-bar {
      color: #fefefe;
      font-family: Staatliches;
      font-size: 45px;
      font-weight: 400;
      position: relative;
      text-align: left;
      margin: 0;
      @include media-breakpoint-down(md) {
        font-size: 26px;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }

      &:after {
        content: '%';
        font-family: Archivo;
        font-size: 25px;
        font-weight: 400;
        vertical-align: super;
        padding-right: 75px;
      }
    }
    .bar {
      height: 119px;
      width: 100%;
      border: 1px solid #ffffff;
      background-color: #ffffff;
      position: relative;
      span {
        background-color: #d2d2d2;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: block;
        z-index: 1;
      }
    }
    .text-bar {
      color: #fefefe;
      font-family: Archivo;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      padding-top: 6px;
      @include media-breakpoint-down(sm) {
        max-width: 90px;
      }
    }
  }
  .km-g, .km-d {
    width: 50%;
    float: left;
    padding-bottom: 3px;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .graph-chr1 {
      color: #fefefe;
      font-family: Archivo;
      font-size: 30px;
      font-weight: 700;
    }
    .graph-txt {
      font-family: "Archivo";
      font-size: 11px;
      font-weight: 700;
      letter-spacing: -0.22px;
      line-height: 10px;
    }
    .graph-chr2 {
      color: #fefefe;
      font-family: Archivo;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      margin-top: 10px;
      text-align: left;
      margin: 0;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      &:after {
        content: '%';
        font-family: Archivo;
        font-size: 20px;
        font-weight: 400;
        padding-right: 75px;
        @include media-breakpoint-down(sm) {
          padding-right: 0;
        }
      }
    }
  }
  .graph-chiffre3 {
    color: #fefefe;
    font-family: Staatliches;
    font-size: 45px;
    font-weight: 400;
    position: relative;
    text-align: left;
    margin: 0;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &:after {
      content: '%';
      font-family: Archivo;
      font-size: 25px;
      font-weight: 400;
      vertical-align: super;
      padding-right: 75px;
      @include media-breakpoint-down(sm) {
        padding-right: 0;
      }
    }
  }
}

.line-bot {
  padding-bottom: 8px;
  border-bottom: 1px solid white;
  clear: both;
}

// .dr-t3 {
//   margin-top: -200px;
//   @include media-breakpoint-down(md) {
//     margin-top: -160px;
//   }
//   @include media-breakpoint-down(sm) {
//     margin-top: 0px;
//   }
// }

.dr-t2-end {
  margin-top: -250px;
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }
}

.dr-t6 {
  @include media-breakpoint-down(sm) {
    padding-top: 0px;
  }
}

.counterUp {
  animation-duration: 1s;
  animation-delay: 0s;
}