#page-contact {
  background-color: $background-black;

  padding: 30px 0;

  h2 {
    color: $white;
    font-size: 70px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    margin-bottom: 0;
  }
  .page-contact-form {
    max-width: 460px;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
    margin: 0 auto;
    .gform_wrapper {
      form {
        .gform_body {
          .gform_fields {
            .gfield {
              margin: 0;
              padding: 0;

              .gfield_label {
                display: none;
              }

              .ginput_container {
                margin-top: 30px;
                input {
                  width: 100%;
                  background-color: transparent;
                  border: 1px solid $white;
                  padding: 10px 14px;
                  color: $white;
                  font-family: Staatliches;
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 18px;
                  &::placeholder {
                    color: #fff;
                  }
                }
                textarea {
                  background-color: transparent;
                  border: 1px solid $white;
                  padding: 20px 25px;
                  color: $white;
                  font-family: Staatliches;
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 18px;
                  &::placeholder {
                    color: #fff;
                  }
                }
              }

              &.gfield_error {
                max-width: 100% !important;
                width: 100%;

                background-color: transparent;

                border: none;

                .validation_message {
                  padding-top: 5px;
                  padding-right: 0;

                  font-family: Archivo;
                  color: $orange;
                  font-weight: normal;
                  position: absolute;
                }
              }
            }
          }
        }

        .gform_footer {
          margin: 50px 0 0;

          text-align: right;

          .gform_button {
            margin: 0;
            padding: 5px 60px;

            background-color: transparent;
            border: 1px solid $orange;
            border-radius: 10px 10px 10px 0;

            color: $white;
            font-family: Archivo;
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;

            cursor: pointer;

            transition: all ease .2s;

            &:hover {
              background-color: $orange;
            }
          }
        }

        .validation_error {
          border: none;

          font-family: Archivo;
          color: $orange;
          font-weight: normal;
          padding: 0;
        }
      }
    }

    .gform_confirmation_wrapper {
      .gform_confirmation_message {
        color: $white;
        font-family: Staatliches;
        font-size: 20px;
        font-weight: normal;
        line-height: 25px;
        text-align: center;
      }
    }
  }
}

#tpl_contact {
  .contact-block {
    min-height: 76vh;
    .gform_confirmation_wrapper {
      padding-top: 20px;
    }
  }
}
