#page-search {
  // background-color: $background-black;
  padding: 20px 0;
  .form-close-block {
    position: fixed;
    z-index: 11;
    left: 75%;
    right: 25%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @include media-breakpoint-down(md) {
      max-width: 960px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 720px;
    }
    @include media-breakpoint-down(xs) {
      max-width: 540px;
    }
    .form-close {
      float: right;
      width: 32px;
      height: 32px;
      background-image: url(../img/icon-close.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      margin-right: 13px;
      margin-top: 4px;
    }
  }
  .page-search-form {
    position: relative;
    padding: 200px 0 0;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding: 60px 0 0;
    }
    .input-container {
      .search-input {
        width: 100%;
        margin: 0 0 130px;
        background-color: transparent;
        border: none;
        color: $white;
        font-family: Staatliches;
        font-size: 100px;
        font-weight: normal;
        @include media-breakpoint-down(lg) {
          font-weight: normal;
          text-align: center;
          margin: 0 0 30px;
          font-size: 35px;
          margin: 40px 0 40px;
        }
        @include media-breakpoint-down(md) {
          font-size: 35px;
        }
      }

      .search-submit {
        margin: 0;
        padding: 5px 60px;

        background-color: transparent;
        border: 1px solid $orange;
        border-radius: 10px 10px 10px 0;

        color: $white;
        font-family: Archivo;
        font-size: 12px;
        font-weight: 400;
        line-height: 10px;

        cursor: pointer;

        transition: all ease .2s;

        &:hover {
          background-color: $orange;
        }
      }
    }

    .gform_wrapper {
      form {
        .gform_body {
          .gform_fields {
            .gfield {
              margin: 0;
              padding: 0;

              .gfield_label {
                display: none;
              }

              .ginput_container {
                margin-top: 30px;

                input {
                  width: 100%;

                  background-color: transparent;
                  border: 1px solid $white;

                  padding: 20px 25px;

                  color: $white;
                  font-family: Staatliches;
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 18px;
                }

                textarea {
                  background-color: transparent;
                  border: 1px solid $white;

                  padding: 20px 25px;

                  color: $white;
                  font-family: Staatliches;
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 18px;
                }
              }

              &.gfield_error {
                max-width: 100% !important;
                width: 100%;

                background-color: transparent;

                border: none;

                .validation_message {
                  padding-top: 5px;
                  padding-right: 0;

                  font-family: Archivo;
                  color: $orange;
                  font-weight: normal;
                }
              }
            }
          }
        }

        .gform_footer {
          margin: 50px 0 0;

          text-align: right;

          .gform_button {
            margin: 0;
            padding: 5px 60px;

            background-color: transparent;
            border: 1px solid $orange;
            border-radius: 10px 10px 10px 0;

            color: $white;
            font-family: Archivo;
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;

            cursor: pointer;

            transition: all ease .2s;

            &:hover {
              background-color: $orange;
            }
          }
        }

        .validation_error {
          border: none;

          font-family: Archivo;
          color: $orange;
          font-weight: normal;
        }
      }
    }

    .gform_confirmation_wrapper {
      .gform_confirmation_message {
        color: $white;
        font-family: Staatliches;
        font-size: 20px;
        font-weight: normal;
        line-height: 25px;
        text-align: center;
      }
    }
  }
}

.searchfullmenu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;

  pointer-events: none;

  &:before, &:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(20, 21, 26, 0.6);
    //border-bottom-left-radius: 200%;
    z-index: -1;
    -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
    transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
    transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, -webkit-transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
    -webkit-transform: translateX(100%) translateY(0);
    transform: translateX(100%) translateY(0);
  }

  &:after {
    background: $background-black;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }

  &:before {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
  }

  .search__content {
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    text-align: center;

    #page-search {
      visibility: hidden;
      height: 100%;

      -webkit-transition-delay: .5s;
      transition-delay: .5s;
    }
  }
}

body.search-active {

  .searchfullmenu {
    visibility: visible;

    pointer-events: all;

    &:before, &:after {
      -webkit-transform: translateX(0%) translateY(0%);
      transform: translateX(0%) translateY(0%);
      border-radius: 0;
    }

    &:after {
      -webkit-transition-delay: .1s;
      transition-delay: .1s;
    }

    &:before {
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }

    .search__content {
      visibility: visible;

      #page-search {
        visibility: visible;
      }
    }
  }
}

#page-result {
  .main-title {
    position: relative;
    display: inline-block;
    &:after {
      //content: '|';
      content: none;
      margin: 0 0 0 30px;
    }
  }
  .result-title {
    color: $black;
    padding-top: 20px;
  }
}

.content-wrapper {
  min-height: 80vh;
}