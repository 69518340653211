/*
|-----------------------
| 	     FONTS
|-----------------------
|
*/

// 100    Extra Light or Ultra Light
// 200    Light or Thin
// 300    Book or Demi
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black