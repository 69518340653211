/*
|-----------------------
| 	      HOME
|-----------------------
|
*/

.single-content {
  padding: 0 0 40px 0;
  .bg-single {
    height: 450px;
    display: block;
    transition: all 0.5s ease-in-out;
    transform: scale(1);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      height: 66vh;
    }
  }
  .fil-ariane {
    color: #323232;
    font-family: Staatliches;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    margin: 40px 0 60px 0;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .article-cat {
    color: #f7a600;
    font-family: Staatliches;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 30px;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }
  .article-title {
    color: #000000;
    font-family: Staatliches;
    font-size: 61px;
    font-weight: 400;
    line-height: 61px;
    padding-top: 10px;
    @include media-breakpoint-down(lg) {
      font-size: 43px;
      line-height: 43px;
    }
    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 32px;
    }
  }
  .article-date {
    color: #808080;
    margin: 7px 0 25px 0;
    font-family: Archivo;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      margin: 7px 0 5px 0;
    }
  }
  .article-rs {
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0px;
    }
    @include media-breakpoint-down(md) {
      margin-left: 80px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .single-content img {
      margin: 0;
    }
  }
  .article-intro {
    position: relative;
    color: #000000;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 30px;
    z-index: -1;
    @include media-breakpoint-down(lg) {
      font-size: 18px;
      line-height: 23px;
    }
    #duree_lecture,
    .duree_lecture {
      position: absolute;
      left: -89px;
      canvas {
        width: 100% !important;
        height: auto !important;
        max-width: 70px;
      }
    }
    //@media (max-width: 900px) {
    //  #duree_lecture {
    //    position: relative;
    //    left: initial;
    //    margin-bottom: 10px;
    //    canvas {
    //      width: 100% !important;
    //      height: auto !important;
    //      max-width: 70px;
    //    }
    //  }
    //}
    @include media-breakpoint-down(md) {
      #duree_lecture,
      .duree_lecture {
        position: absolute;
        top: 0;
        left: -90px;
      }
    }
  }
  .article-podcast {
    clear: both;
    width: 100%;
    height: 73px;
    margin: 35px 0px 35px 0px;
    a {
      color: #f7a600;
      font-family: Staatliches;
      font-size: 20px;
      font-weight: 400;
      line-height: inherit;
      float: left;
      margin-top: 25px;
      margin-left: 25px;
      display: block;
    }
    &:before {
      content: "";
      background-image: url('../../src/img/podcast.png');
      height: 73px;
      width: 73px;
      display: block;
      float: left;
    }
  }

  .article_like {
    width: fit-content;
    .txt-like {
      color: #000000;
      font-family: Staatliches;
      font-size: 20px;
      font-weight: 400;
      line-height: inherit;
      padding-top: 1px;
      display: inline-block;
      padding-right: 10px;
    }
    .likebtn-wrapper {
      float: right;
    }
    .lb-style-custom {
      .lb-like-label {
        color: #ffffff !important;
        font-family: Staatliches !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        padding: 0 8px !important;
      }
    }
  }

  .lb-count {
    color: rgb(255, 255, 255) !important;
    font-family: Staatliches !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    padding: 0px 8px !important;
    display: inline-block;
  }

  h2 {
    font-family: Staatliches;
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding: 30px 0 20px 0;
  }
  .citation {
    color: #d2d2d2;
    font-family: "Archivo";
    font-size: 34px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
    padding: 50px 0 40px 0;
    .auteur {
      color: #000;
      font-family: "Archivo";
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-transform: uppercase;
      padding-top: 20px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    margin: 30px 0;
  }
}

.article_tags {
  margin: 40px 0px;
  @include media-breakpoint-down(md) {
    margin: 20px 0px;
  }
  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }
  li {
    display: inline-block;
    background-color: #aaaaaa;
    color: #ffffff;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    padding: 5px 11px;
    margin-bottom: 3px;
    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 16px;
    }
    a {
      color: #ffffff;
    }
  }
}

.block-big-nbr {
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 0;
  }
  .big-nbr {
    color: #dadada;
    font-family: Staatliches;
    font-size: 80px;
    font-weight: 400;
    line-height: 63px;
    top: 0px;
    right: 20px;
    position: absolute;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      margin-top: 20px;
      left: 0px;
      padding: 0;
      display: block;
      position: relative;
    }
  }
  .big-nbr2 {
    color: #dadada;
    font-family: Staatliches;
    font-size: 80px;
    font-weight: 400;
    display: block;
    line-height: 63px;
    padding-bottom: 10px;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }
}

.block-single-portrait {
  margin-bottom: 40px;
  .bg-single-portrait {
    display: block;
    transition: all 0.5s ease-in-out;
    transform: scale(1);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      height: 450px;
      margin-bottom: 30px;
      background-size: cover;
    }
  }
  .article-intro {
    margin: 0;
    margin-top: 30px;
    @include media-breakpoint-down(md) {
      margin-top: 0px;
    }
  }
  #duree_lecture,
  .duree_lecture {
    left: -90px;
  }
}

.list-archive {
  .article-rs {
    margin-left:0!important;
  }
}

.zone-portrait {
  margin-top: 130px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .zone-portrait-contenu {
    height: 105px;
    background-color: #f7a600;
    @include media-breakpoint-down(md) {
      height: initial;
      background: none;
      text-align: center;
    }
    .pd-ls {
      padding-left: 30px;
      @include media-breakpoint-down(lg) {
        padding-left: 0px;
      }
    }
    .contact_portrait {
      width: 146px;
      height: 146px;
      border-radius: 100px;
      background-position: center;
      background-size: cover;
      margin-top: -70px;
      @include media-breakpoint-down(lg) {
        width: 90px;
        height: 90px;
        margin-top: 7px;
      }
      @include media-breakpoint-down(md) {
        margin: auto;
      }

    }
    .label {
      color: #000000;
      margin-top: 23px;
      font-family: Staatliches;
      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
    }
    .articles_contact_title {
      color: #ffffff;
      font-family: Staatliches;
      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
      @include media-breakpoint-down(md) {
        color: #aaaaaa;
        margin-bottom: 10px;
      }
    }
    .articles_contact_liens {
      li {
        float: left;
        margin-right: 10px;
        @include media-breakpoint-down(md) {
          float: initial;
          margin-right: 10px;
          margin-bottom: 20px;
          display: inline-block;
        }
        a {
          &:hover {
            opacity: 0.5;
          }
          img {
            height: 22px;
            width: 22px;
            margin: 0;
          }
        }
      }
    }
  }
  .zone-portrait-link {
    height: 105px;
    border-radius: 10px 10px 10px 0;
    background-color: #f7a600;
    color: #ffffff;
    font-family: Staatliches;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    padding-top: 40px;
    display: block;
    cursor: pointer;
  }
}

.article-zone,
.article-zone2 {
  padding: 15px;
  h2 {
    padding: 5px 0 20px 0;
  }
}

.article-zone2 {
  height: 100%;
}

.pdc {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 20px;
  background-image: url('../../src/img/pdc.png');
  height: 42px;
  width: 24px;
  display: block;
}

.bdr {
  border: 1px solid #000;
}

.dis-flex {
  display: flex;
}

.zone-img {
  background-size: cover;
  background-position: center center;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 20px;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    position: relative;
    margin: 40px 0 30px 0;
    left: 0px;
    right: 0px;
  }
  .swiper-slide {
    background-size: cover;
    background-position: center center;
    height: 448px;
    width: 740px;
  }
}

.mgb-md {
  margin-bottom: 60px;
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.mgt-md {
  margin-top: 60px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

#article-h {
  height: 800px;
}

.mg-mob {
  margin-left: 0px;
  margin-right: 0px;
}

@include media-breakpoint-down(sm) {
  .mg-mob {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.sfcm.sfsi_wicon {
  padding: 0;
  margin: 0 !important;
}

.full-img {
  height: 450px;
  margin: 20px 0 40px 0;
  background-position: center;
  background-size: cover;
}

.txt-rea,
.txt-rea2 {
  height: 100vh;
  @include media-breakpoint-down(sm) {
    height: inherit;
    margin: 0 20px;
    min-height: 120vh;
  }
  .title-rea {
    margin-top: 160px;
    .actu-cat {
      color: #f7a600;
      font-family: Staatliches;
      letter-spacing: 1px;
      font-size: 22px;
      font-weight: 400;
      line-height: 22px;
    }
    .actu-title {
      font-family: Staatliches;
      display: block;
      color: #fff;
      font-size: 40px;
      font-weight: 400;
      line-height: 40px;
      margin-top: 10px;
      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 26px;
      }
    }
  }
  .content-rea {
    background: white;
    padding: 30px 0;
    position: absolute;
    bottom: -1px;
    width: 80%;
    height: 130px;
    overflow: hidden;
    transition: all ease 0.5s;
    &.active {
      height: auto;
      transition: all ease 0.5s;
      .switch-desc {
        transform: rotate(0deg);
      }
    }
    .switch-desc {
      background-image: url(../img/arrow-down.png);
      background-repeat: no-repeat;
      background-position: center;
      height: 7px;
      width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      top: 0;
      padding: 22px;
      cursor: pointer;
      transform: rotate(180deg);
      // @include media-breakpoint-down(sm) {
      //   display: none;
      // }
    }
    .actu-text {
      padding: 0 30px;
      margin-top: 10px;
      font-weight: 400;
      line-height: 17px;
      display: block;
      color: #000000;
      font-family: Archivo;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }
    }
    .article_tags {
      margin: 20px 0px;
      padding: 0 30px;
      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }
    }
    .article-rs {
      padding: 0 30px;
      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .txt-rea2 {
    position: absolute;
    top: 100px;
    right: 0;
  }
}

.fiche_chantier {
  position: absolute;
  right: 0px;
  left: 0;
  top: 0;
  bottom: 0;

  .fiche_chantier_off {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 62%;
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: #8a8888;
    font-family: Staatliches;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      &:after {
        content: "";
        transform: rotate(90deg);
        transition: all ease 0.2s;
      }
    }
    &:after {
      content: "";
      background-image: url('../../src/img/plus.png');
      height: 18px;
      width: 18px;
      text-align: center;
      display: block;
      margin: 3px auto 0 auto;
      transform: rotate(0deg);
      transition: all ease 0.2s;
    }
  }
  .fiche_chantier_on {
    z-index: 2;
    width: 380px;
    position: absolute;
    display: none;
    right: 0;
    background: #fff;
    border-radius: 10px;
    border-top-right-radius: 0;
    @include media-breakpoint-down(sm) {
      bottom: initial;
      right: 0px;
      width: 88%;
    }

    .close {
      display: block;
      float: right;
      margin: 5px 5px 0 0;
      text-align: center;
      padding: 10px;
      border-radius: 50%;
      width: 72px;
      height: 72px;
      background-color: #ffffff;
      color: #000;
      font-family: Staatliches;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      opacity: 1;
      &:after {
        content: "";
        background-image: url('../../src/img/plus.png');
        height: 18px;
        width: 18px;
        text-align: center;
        display: block;
        margin: 3px auto 0 auto;
        transform: rotate(45deg);
      }
    }
    .text {
      padding: 50px 30px 30px 20px;
      @include media-breakpoint-down(sm) {
        padding: 50px 60px 30px 15px;
      }
    }
    h2 {
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
    p {
      color: #aaaaaa;
      padding: 0 0 10px 0;
      margin: 0;
    }
  }
}

.bg-single-z {
  z-index: 1;
  position: relative;
}

.fc-single {
  bottom: 0;
  top: inherit;
  right: -5px;
  .fiche_chantier_off {
    top: -33px;
  }
  .fiche_chantier_on {
    height: fit-content;
    top: -38px;
    right: -5px;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 3px 5px 1px;
  }
}

.chantier_rea {
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
  .fiche_chantier_on {
    bottom: 0;
  }
}

.jssocials-share-link{
  border: none!important;
  box-shadow: none!important;
  border-radius: 50px!important;
  width: 50px!important;
  height: 50px!important;
  padding: 0!important;;
  margin: 0!important;
  img.jssocials-share-logo{
    margin: 13px 0!important;
  }
}


