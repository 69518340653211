/*
|-----------------------
| 	    VARIABLES
|-----------------------
|
*/
$default-color: #000;

$background-black: #323232;

$orange: #eda944;