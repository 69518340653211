/*
|-----------------------
| 	  SIDE BUTTONS
|-----------------------
|
*/

.side-buttons {
  position: fixed;
  z-index: 11;
  top: 85px;
  right: 25px;
  transform: rotateZ(-90deg);
  transform-origin: bottom right;
  @include media-breakpoint-down(lg) {
    top: 160px !important;
    right: 28px;
  }
  @include media-breakpoint-down(md) {
    top: 110px !important;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  span {
    position: relative;
    text-transform: uppercase;
    margin: 0px 0px 0px 15px;
    padding: 3px 37px 3px 5px;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    font-family: Staatliches;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      margin: 0px 0px 0px 5px;
      padding: 3px 30px 3px 5px;
      font-size: 14px;
    }
    &:hover {
      color: #f7a600 !important;
    }
    &.side-button-newsletter {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0;
        width: 30px;
        height: 30px;
        display: block;
        transform: rotate(90deg);
        background-image: url(../img/ico2.png);
        background-size: cover;
        @include media-breakpoint-down(lg) {
          background-size: contain;
          top: 2px;
          width: 22px;
          height: 22px;
        }
      }
    }
    &.side-button-search {
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0;
        width: 30px;
        height: 30px;
        display: block;
        transform: rotate(90deg);
        background-image: url(../img/ico1.png);
        background-size: cover;
        @include media-breakpoint-down(lg) {
          background-size: contain;
          top: 2px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    //display: none;
    position: absolute;
    z-index: 11;
    top: 6px !important;
    right: 0 !important;
    left: 0 !important;
    margin: auto;
    text-align: center;
    transform: rotateZ(0deg);
  }
}

#tpl_contact,
#tpl_recherche {
  .side-buttons {
    a {
      color: #fff;
    }
  }
}
