/*
|-----------------------
| 	    CONTENT
|-----------------------
|
*/
#page-error {
  background-color: $background-black;
  display: flex;
  height: 100vh;
  align-items: center;
  .content-wrapper {
    margin: 0 auto;
  }
  .page-error-container {
    text-align: center;
    h2 {
      color: $white;
      text-align: center;
    }
    .btn {
      background-color: $background-black;
      color: $white;
      font-size: 12px;
      &:hover {
        background-color: $orange;
      }
    }
  }
}