.newsfullmenu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;

  pointer-events: none;

  &:before, &:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(20, 21, 26, 0.6);
    //border-bottom-left-radius: 200%;
    z-index: -1;
    -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
    transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
    transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, -webkit-transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
    -webkit-transform: translateX(100%) translateY(0);
    transform: translateX(100%) translateY(0);
  }

  &:after {
    background: $background-black;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }

  &:before {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
  }

  .news__content {
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    text-align: center;

    #page-news {
      padding: 20px 0;
      visibility: hidden;
      height: 100%;
      -webkit-transition-delay: .5s;
      transition-delay: .5s;
      position: relative;
      .form-close-block {
        position: fixed;
        z-index: 11;
        left: 75%;
        right: 25%;
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        @include media-breakpoint-down(md) {
          max-width: 960px;
        }
        @include media-breakpoint-down(sm) {
          max-width: 720px;
        }
        @include media-breakpoint-down(xs) {
          max-width: 540px;
        }
        .form-close {
          float: right;
          width: 32px;
          height: 32px;
          background-image: url(../img/icon-close.png);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: block;
          margin-right: 13px;
          margin-top: 4px;
        }
      }
      h2 {
        color: #fff;
        font-size: 70px;
        font-weight: 400;
        line-height: 50px;
        text-align: center;
        margin-bottom: 20px;
      }
      .txt-news {
        color: #fff;
        font-size: 16px;
      }

      .page-news-form {
        position: relative;
        padding: 0;
        margin: 0 auto;


      }

      .widget {
        max-width: 600px;
        margin: 0 auto;

        & > p {
          color: #fff;
          font-size: 16px;
          text-align: left;
        }

        form {  

          label {
            display: none;
          }

          p {
            margin-bottom: 0;

            &:last-of-type {
                display: none;
            }
          }
                  input {
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid #fff;
                    padding: 10px 14px;
                    margin: 0 0;
                    color: #fff;
                    font-family: Staatliches;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 18px;
                    &::placeholder {
                      color: #fff;
                    }
                  }

                  input[type="submit"] {
                    margin: 30px 0 0 0;
                    padding: 5px 60px;
                    background-color: transparent;
                    border: 1px solid #eda944;
                    border-radius: 10px 10px 10px 0;
                    color: #fff;
                    font-family: Archivo;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 10px;
                    cursor: pointer;
                    transition: all ease .2s;
                    width: auto;

                    &:hover {
                        background-color: #eda944;
                    }
                  }

                  textarea {
                    background-color: transparent;
                    border: 1px solid #fff;
                    padding: 20px 25px;
                    color: #fff;
                    font-family: Staatliches;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 18px;
                    &::placeholder {
                      color: #fff;
                    }
                  }
                }
              }
          

          .gform_footer {
            text-align: center;

            margin: 0;
            padding: 0;

            .gform_button {
              margin: 30px 0 0 0;
              padding: 5px 60px;

              background-color: transparent;
              border: 1px solid $orange;
              border-radius: 10px 10px 10px 0;

              color: $white;
              font-family: Archivo;
              font-size: 12px;
              font-weight: 400;
              line-height: 10px;

              cursor: pointer;

              transition: all ease .2s;

              &:hover {
                background-color: $orange;
              }
            }
          }

          .validation_error {
            border: none;

            font-family: Archivo;
            color: $orange;
            font-weight: normal;
            padding: 40px 0 0 0;
          }
        }
  

      .gform_confirmation_wrapper {
        .gform_confirmation_message {
          color: $white;
          font-family: Staatliches;
          font-size: 20px;
          font-weight: normal;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }


body.newsletter-active {

  .newsfullmenu {
    visibility: visible;

    pointer-events: all;

    &:before, &:after {
      -webkit-transform: translateX(0%) translateY(0%);
      transform: translateX(0%) translateY(0%);
      border-radius: 0;
    }

    &:after {
      -webkit-transition-delay: .1s;
      transition-delay: .1s;
    }

    &:before {
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }

    .news__content {
      visibility: visible;

      #page-news {
        visibility: visible;
        @include media-breakpoint-down(sm) {
          overflow-y: scroll;
        }
      }
    }
  }
}