/*
|-----------------------
| 	     FOOTER
|-----------------------
|
*/

.legals {
  background-color: #000;
  .footer-menu {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 10px 0 25px 0;
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    div {
      margin: 10px auto 0 auto;
    }

    /* Text legal */
    .txt-footer-1 {
      padding: 25px 5px 36px 15px;
      text-align: left;
      font-weight: 300;
      font-size: 12px;
      display: inherit;
      @include media-breakpoint-down(md) {
        text-align: center;
        padding: 10px 15px;
      }
    }

    /* Menu */
    .txt-footer-2 {
      padding: 0;
      margin: 0 auto;
      @include media-breakpoint-down(md) {
        margin: 0;
        padding: 10px 15px;
      }
      ul {
        display: inline-block;
        margin: 0;
        li {
          color: #ffffff;
          font-family: Archivo;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          display: inline-block;
          position: relative;
          padding: 0 5px;
          @media(max-width: 1349px) {
            padding: 0 5px;
          }
          @include media-breakpoint-down(md) {
            padding: 0 5px;
          }
          &.magazine {
            font-weight: 600;
            height: 35px;
            vertical-align: bottom;
            @include media-breakpoint-down(md) {
              width: 100%;
              clear: both;
            }
          }
          &.sep-footer {
            &:after {
              content: "";
              color: #fff;
              display: inline-block;
              height: 15px;
              border-left: 1px solid #ffffff;
              vertical-align: middle;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }
          }
          a {
            font-family: Archivo;
            padding: 0 10px;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }

    /* Reseau sociaux */
    .block-rs {
      text-align: right;
      @include media-breakpoint-down(md) {
        text-align: center;
        margin: 0px auto 0 auto;
        padding-bottom: 20px;
      }
      .rs {
        margin-top: 15px;
        border-radius: 50%;
        background-color: #fff;
        width: 40px;
        height: 40px;
        display: inline-block;
        margin-right: 12px;
        &.in-rs {
          background-image: url('../img/in-rs.svg');
        }
        &.fb-rs {
          background-image: url('../img/fb-rs.svg');
        }
      }
    }
  }
}

//#tpl_mentions-legales{
//  #footer{
//    bottom: 0;
//    position: absolute;
//  }
//}

.cookiebanner {
  color: #000;
  a {
    color: #000;
    text-decoration: underline;
  }
}

.clearfixfooter {
  clear: both;
  width: 100%;
}