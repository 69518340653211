/*
|-----------------------
| 	    DEFAULTS
|-----------------------
|
*/

body {
  font-family: Archivo;
  color: $default-color;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  overflow-x: hidden;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;

  header {
    padding: 0 15px;
    background-color: #000;
    @include media-breakpoint-down(lg) {
      padding: inherit;
    }
  }

  &.home {
    header {
      height: 90vh;
      padding: 0;
      background-color: initial;
      .header-home {
        .menu-icon {
          position: absolute;
          right: 15px;
          top: 40px;
          z-index: 99;
          @include media-breakpoint-down(sm) {
            right: 30px;
          }
        }
        .navbar {
          padding: 0;
        }
        .navfullmenu {
          top: 0px;
        }
      }
    }
  }

}

.hidden-sm {
  display: block;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h2 {
  font-family: Staatliches;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 40px 0 20px 0;
}

a {
  color: #eda944;

  &:hover {
    color: black;
  }
}

.btn.loadMoreBtn {
  color: #333333;
  text-align: center;
}

.btn.loadMoreBtn:hover {
  text-decoration: none;
}

.cookiebanner {
  padding: 3px 16px 4px 16px !important;
  border-top: 1px solid #d0d0d0;
  span {
    padding-top: 3px;
    display: inline-block;
    a {
      text-decoration: underline !important;
    }
  }
  .cookiebanner-close {
    padding: 2px 8px;
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    &:hover {
      background-color: #fff;
      border: 1px solid #000;
      color: #000;
    }
  }
}

.nopad {
  padding-left: 0;
  padding-right: 0;
}

.nomarg {
  margin-left: 0;
  margin-right: 0;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.cursor,
.cursor2,
.cursor3 {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  left: -100px;
  top: 50%;
  mix-blend-mode: soft-light;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.cursor {
  background-color: #fff;
  height: 0;
  width: 0;
  z-index: 99999999;
}

.cursor2, .cursor3 {
  height: 36px;
  width: 36px;
  z-index: 99999998;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out
}

.cursor2.hover,
.cursor3.hover {
  -webkit-transform: scale(2) translateX(-25%) translateY(-25%);
  transform: scale(2) translateX(-25%) translateY(-25%);
  border: none
}

.cursor2 {
  border: 1px solid #c7c7c7;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
}

.cursor2.hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1200px) {
  .cursor, .cursor2, .cursor3 {
    display: none
  }
}

.over-hide {
  overflow: hidden;
}

.full-height {
  height: 100vh;
}

.navbar-brand {
  // padding: 0;
  // padding-top: 10px;

  height: 100%;
    display: flex;
    align-items: center;
  @include media-breakpoint-down(sm) {
    // padding-top: 3px;
  }
}

.c_blanc {
  color: #fff !important;
  transition: all ease 0.3s;
}

.c_noir {
  color: #000 !important;
  transition: all ease 0.3s;
}

/* #Navigation
================================================== */

.cd-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-wrapper {
  position: relative;
  width: calc(100% - 100px);
  margin-left: 50px;
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-left: 0px;
  }
}

.logo-wrap {
  position: absolute;
  display: block;
  left: 0;
  top: 10px;
  cursor: pointer;
}

.logo-wrap a {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  transition: all 0.3s ease-out;
}

.logo-wrap a span {
  color: #8167a9;
}

.logo-wrap a:hover {
  opacity: 0.9;
}

.nav-but-wrap {
  position: relative;
  display: inline-block;
  float: right;
  padding-left: 15px;
  padding-top: 15px;
  margin-top: 26px;
  transition: all 0.3s ease-out;
}

.menu-icon {
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 99999;
  cursor: pointer;
  display: block;
  float: right;
}

.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: #fff;
  margin-bottom: 7px;
  cursor: pointer;
  -webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
  transition: background-color .5s ease, -webkit-transform .2s ease;
  transition: transform .2s ease, background-color .5s ease;
  transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
}

.menu-icon__line-left {
  width: 16.5px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon__line-right {
  width: 16.5px;
  float: right;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.menu-icon:hover .menu-icon__line-left,
.menu-icon:hover .menu-icon__line-right {
  width: 30px;
}

.navfullmenu {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 98;
  @include media-breakpoint-down(sm) {
    top: 0px;
  }
}

.navfullmenu:before, .navfullmenu:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(20, 21, 26, 0.6);
  //border-bottom-left-radius: 200%;
  z-index: -1;
  -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
  transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.4s;
  transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
  transition: transform cubic-bezier(1, 0, 0.175, 1) 0.4s, -webkit-transform cubic-bezier(1, 0, 0.175, 1) 0.4s, border-radius linear 0.4s;
  -webkit-transform: translateX(100%) translateY(0);
  transform: translateX(100%) translateY(0);
}

.navfullmenu:after {
  background: $background-black;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.navfullmenu:before {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.nav__content {
  position: fixed;
  visibility: hidden;
  top: 50%;
  margin-top: 20px;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  width: 100%;
  text-align: center;
  @include media-breakpoint-down(sm) {
    padding: 40px 0;
    top: initial;
    margin-top: 0px;
    -webkit-transform: initial;
    transform: initial;
    width: 100%;
    overflow: scroll;
    text-align: center;
    height: 100vh;
  }
}

.nav__title {
  font-family: Staatliches;
  color: #fff;
  font-size: 70px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  margin-bottom: 50px;
  transition-delay: 0.4s;
  @include media-breakpoint-down(lg) {
    font-size: 37px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 35px;
    margin-bottom: 0px;
    display: none;
  }
}

.nav__list {
  position: relative;
  z-index: 2;
  width: 275px;
  height: 380px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #ffffff;
  padding: 60px 25px;
  margin: 0 12px;
  transition-delay: 0.4s;
  @include media-breakpoint-down(lg) {
    height: 320px;
    padding: 20px 25px;
  }
  @include media-breakpoint-down(sm) {
    width: inherit;
    height: auto;
    padding: 10px 50px;
    margin: 0;
    border: 0;
  }
}

.nav__list-item {
  position: relative;
  display: block;
  transition-delay: 0.4s;
  opacity: 0;
  text-align: center;
  color: #fff;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.15;
  letter-spacing: inherit;
  -webkit-transform: translate(100px, 0%);
  transform: translate(100px, 0%);
  -webkit-transition: opacity .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, transform .4s ease;
  transition: opacity .4s ease, transform .4s ease, -webkit-transform .4s ease;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  margin: 16px 0;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  &:first-child {
    margin: 0 0 27px;
    @include media-breakpoint-down(sm) {
      margin: 0px;
    }
    a {
      font-size: 40px;
      color: $orange;
      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 13px 0;
  }
}

.nav__list-item a {
  font-family: Staatliches;
  font-size: 20px;
  position: relative;
  text-decoration: none;
  color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  z-index: 2;
  display: inline-block;
  text-transform: uppercase;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

.nav__list-item a:after {
  position: absolute;
  content: '';
  top: 50%;
  margin-top: -2px;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: #8167a9;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.nav__list-item a:hover:after {
  //height: 4px;
  opacity: 1;
  left: 0;
  width: 100%;
}

.nav__list-item a:hover {
  color: $orange;
}

.nav__list-item.active-nav a {
  color: $orange;
}

.nav__list-item.active-nav a:after {
  //height: 4px;
  opacity: 1;
  left: 0;
  width: 100%;
}

.nav__footer {
  margin: 40px 0 0;
  transition-delay: 0.35s;
  @include media-breakpoint-down(md) {
    margin: 0;
    margin-top: 10px;
  }
  .nav__footer-btn {
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 50px;
    min-width: 110px;
    padding: 0 10px;
    font-family: Staatliches;
    font-size: 20px;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 160px;
      margin: auto;
      display: block;
      font-size: 28px;
    }
    &:hover {
      color: #eda944;
    }
    &.side-button-search {
      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -30px;
        width: 30px;
        height: 30px;
        display: block;
        background-image: url(../img/icn1.png);
        @include media-breakpoint-down(sm) {
          background-size: contain;
          top: 8px;
          left: -10px;
          width: 22px;
          height: 22px;
        }
      }
    }
    &.side-button-newsletter {
      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -30px;
        width: 30px;
        height: 30px;
        display: block;
        background-image: url(../img/icn2.png);
        @include media-breakpoint-down(sm) {
          background-size: contain;
          top: 9px;
          left: -17px;
          width: 22px;
          height: 22px;
        }
      }
    }
    &.side-button-contact {
      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -30px;
        width: 30px;
        height: 30px;
        display: block;
        background-image: url(../img/icn3.png);
        @include media-breakpoint-down(sm) {
          background-size: contain;
          top: 10px;
          left: 4px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

}

body.nav-active .nav__content {
  visibility: visible;
}

body.nav-active .menu-icon__line {
  background-color: #fff;
  -webkit-transform: translate(0px, 0px) rotate(-45deg);
  transform: translate(0px, 0px) rotate(-45deg);
}

body.nav-active .menu-icon__line-left {
  width: 15px;
  -webkit-transform: translate(2px, 4px) rotate(45deg);
  transform: translate(2px, 4px) rotate(45deg);
}

body.nav-active .menu-icon__line-right {
  width: 15px;
  float: right;
  -webkit-transform: translate(-3px, -3.5px) rotate(45deg);
  transform: translate(-3px, -3.5px) rotate(45deg);
}

body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
  width: 15px;
}

body.nav-active .navfullmenu {
  visibility: visible;
}

body.nav-active .navfullmenu:before, body.nav-active .navfullmenu:after {
  -webkit-transform: translateX(0%) translateY(0%);
  transform: translateX(0%) translateY(0%);
  border-radius: 0;
}

body.nav-active .navfullmenu:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

body.nav-active .navfullmenu:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

body.nav-active .nav__list-item {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
  transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
  transition: opacity .3s ease, transform .3s ease, color .3s ease;
  transition: opacity .3s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
}

body.nav-active .nav__list-item:nth-child(0) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

body.nav-active .nav__list-item:nth-child(1) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

body.nav-active .nav__list-item:nth-child(2) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

body.nav-active .nav__list-item:nth-child(3) {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

body.nav-active .nav__list-item:nth-child(4) {
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

body.nav-active .nav__list-item:nth-child(5) {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

body.nav-active .nav__list-item:nth-child(6) {
  -webkit-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

body.nav-active .nav__list-item:nth-child(7) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

body.nav-active .nav__list-item:nth-child(8) {
  -webkit-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

body.nav-active .nav__list-item:nth-child(9) {
  -webkit-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

body.nav-active .nav__list-item:nth-child(10) {
  -webkit-transition-delay: 0.85s;
  transition-delay: 0.85s;
}

.switch-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  transform: translateY(-50%);
  width: 100%;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  margin: 0 auto;
  text-align: center;
}

.switch-wrap h1 {
  font-weight: 900;
  font-size: 46px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

@media screen and (max-width: 580px) {
  .switch-wrap h1 {
    font-size: 32px;
  }
}

.switch-wrap p {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  color: #8167a9;
  text-align: center;
  margin-top: 15px;
}

.switch-wrap p span {
  position: relative;
}

.switch-wrap p span:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background-color: #fff;
  left: 0;
  bottom: -4px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch-wrap p span:nth-child(2):before {
  opacity: 0;
}

#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

#switch {
  width: 60px;
  height: 8px;
  margin: 0 auto;
  text-align: center;
  border: 2px solid #000;
  border-radius: 27px;
  background: #8167a9;
  position: relative;
  display: inline-block;
}

#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}

.switched {
  border-color: #8167a9 !important;
  background: #000 !important;
}

.switched #circle {
  left: 43px;
  background: #000;
}

/* #Light
================================================== */

body.light {
  background-color: #fff;
}

body.light .cursor,
body.light .cursor2,
body.light .cursor3 {
  mix-blend-mode: normal;
}

body.light .cursor2 {
  border: 2px solid #1f2029;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

body.light .cursor2.hover {
  background: rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}

body.light .logo-wrap a {
  color: #1f2029;
}

body.light .menu-icon__line {
  background-color: #1f2029;
}

body.light .navfullmenu:before {
  background: rgba(235, 235, 235, 0.6);
}

body.light .navfullmenu:after {
  background: rgba(250, 250, 250, 1);
}

body.light .nav__list-item a {
  color: rgba(0, 0, 0, 0.6);
}

body.light .nav__list-item a:hover {
  color: #1f2029;
}

body.light .nav__list-item.active-nav a {
  color: #1f2029;
}

body.light .switch-wrap h1 {
  color: #000;
}

body.light .switch-wrap p span:nth-child(2):before {
  opacity: 1;
  background-color: #000;
}

body.light .switch-wrap p span:nth-child(1):before {
  opacity: 0;
}

/* #Link to page
================================================== */

.link-to-portfolio {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 200;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 3px;
  background-position: center center;
  background-size: cover;
  background-image: url('https://ivang-design.com/ig-logo.jpg');
  box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
  transition: opacity .2s, border-radius .2s, box-shadow .2s;
  transition-timing-function: ease-out;
}

.link-to-portfolio:hover {
  opacity: 0.8;
  border-radius: 50%;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, .1);
}

.tc {
  text-align: center;
}

.top-right {
  margin-top: 30px;
  @include media-breakpoint-down(sm) {
    margin-top: 11px;
  }
}

.title-page {
  h1 {
    color: #f7a600;
    font-family: Staatliches;
    font-size: 71px;
    font-weight: 400;
    line-height: 60px;
    padding-top: 80px;
    padding-bottom: 50px;
    @include media-breakpoint-down(sm) {
      font-size: 40px;
      line-height: 38px;
      padding-top: 40px;
      padding-bottom: 20px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 32px;
      line-height: 30px;
    }
  }
  .top-content {
    color: #000000;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.page-style {
  font-family: Archivo;
  .article-content {
    margin: 40px 0 40px 0px;
    h1 {
      color: #000000;
      font-family: Staatliches;
      font-size: 90px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 15px;
      @include media-breakpoint-down(md) {
        font-size: 60px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 36px;
      }
    }
    h2 {
      font-family: Staatliches;
      font-size: 24px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      padding: 20px 0 10px 0;
    }
    h3 {
      font-family: Staatliches;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      padding: 10px 0 5px 0;
    }
    p {
      font-family: Archivo;
    }
    a {
      font-family: Archivo;
    }
    ul {
      margin-bottom: 18px;
      li {
        list-style-type: disc;
        margin-left: 30px;
        font-family: Archivo;
      }
    }
  }
}

.breadcrumbs {
  li {
    display: inline-block;
    padding: 0 2px;
    &:first-child {
      padding-left: 0;
    }
    a {
      color: #323232;
    }
  }
}

.h450 {
  height: 450px;
  @include media-breakpoint-down(md) {
    height: 66vh;
  }
}

.clearfix {
  clear: both;
}

.zone-block-center {
  display: flex;
  .block-center {
    width: 100%;
    margin: auto;
  }
}

.ias-spinner {
  margin-bottom: 40px;
}

.sfsi_plus_widget{
  height: 55px!important;
}

.disp-d {
  display: block !important;
}
.disp-m {
  display: none !important;
}

@include media-breakpoint-down(sm) {
  .disp-d {
    display: none !important;
  }
  .disp-m {
    display: block !important;
    .content-rea {
      z-index: 1;
      // position: relative!important;
      // height: initial!important;
      // bottom: 0!important;
      // margin: 30px 0!important;
      // width: 100%!important;
      .switch-desc {
        background: none;
        .sfsi_holder {
          height: 45px;
        }
      }
    }
    .bg{
      height: initial!important;
      min-height: 100vh;
    }
  }
}