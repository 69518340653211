/*
|-----------------------
| 	      HOME
|-----------------------
|
*/

.home {
  h2 {
    color: #000000;
    font-family: Staatliches;
    font-size: 90px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 25px;
  }
}

.swiper-slider-home,
.swiper-slider-single {
  .swiper-slide {
    .bg {
      height: 90vh;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .filtre-opa {
        content: '';
        background-color: #000;
        opacity: 0;
        transition: all ease 0.3s;
        height: 90vh;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
      }
      .txt-slider,
      .txt-slider2 {
        position: absolute;
        bottom: 25%;
        left: initial;
        overflow: hidden;
        display: block;
        width: 500px;
        z-index: 3;
        @include media-breakpoint-up(lg) {
          bottom: 8%;
        }
        @include media-breakpoint-up(md) {
          margin-left: 60px;
        }
        @include media-breakpoint-down(md) {
          width: inherit;
        }
        .actu-img {
          height: 220px;
          display: block;
          transition: all 0.5s ease-in-out;
          transform: scale(1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          &:hover {
            transform: scale(1.1);
          }
        }
        .actu-cat {
          color: #f7a600;
          font-family: Staatliches;
          letter-spacing: 1px;
          font-size: 22px;
          font-weight: 400;
          line-height: 22px;
        }
        .actu-title {
          font-family: Staatliches;
          display: block;
          color: #fff;
          font-size: 40px;
          font-weight: 400;
          line-height: 40px;
          @include media-breakpoint-down(lg) {
            font-size: 30px;
            line-height: 26px;
          }
        }
        .actu-date {
          color: #fff;
          margin-top: 10px;
          font-family: "Archivo";
          font-size: 10px;
          font-weight: 700;
          line-height: 17px;
          text-transform: uppercase;
          display: block;
        }
        .actu-text {
          margin-top: 10px;
          font-family: "Archivo";
          color: #fff;
          font-weight: 400;
          line-height: 17px;
          display: block;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        .actu-link {
          background: none;
          color: #fff;
          padding: 2px 13px;
          margin-top: 10px;
          &:hover {
            background-color: #f7a600;
          }
        }
        @include media-breakpoint-down(md) {
          width: 90%;
          position: absolute;
          left: 5%;
          right: 5%;
          bottom: 80px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .swiper-slider-home {
    .swiper-slide {
      .bg {
        background-position: center;
        .txt-slider {
          left: 25px;
          right: 25px;
          bottom: 23%;
        }
      }
    }
  }
}

.swiper-slider-single {
  .swiper-wrapper {
    margin-top: -84px;
  }
  .swiper-slide {
    height: auto;
    .bg {
      position: relative;
      height: 100% !important;
      margin-top: 0;
      .txt-slider {
        top: 11%;
      }
      .txt-slider2 {
        position: absolute;
        width: 308px;
        background-color: #ffffff;
        bottom: 0;
        padding: 30px 25px 10px 25px;
        p {
          color: #000000;
          font-family: Archivo;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
  }
}

.swiper-slider-marques {
  background-color: #f7f7f7;
  padding: 50px 0 70px 0;
  .swiper-slide {
    text-align: center;
  }
}

.single {
  .bg {
    height: 100vh !important;
    margin-top: -84px;
    @include media-breakpoint-down(sm) {
      height: inherit!important;
    }
  }
}

ul.menu-list {
  text-align: center;
  li {
    color: #323232;
    font-family: Staatliches;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    padding: 0 10px;
    position: relative;
    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }
    &:after {
      content: "";
      position: absolute;
      right: -2px;
      top: 6px;
      background-color: #000;
      height: 15px;
      width: 3px;
      @include media-breakpoint-down(sm) {
        content: none;
      }
    }
    a {
      color: #323232;
      &:hover {
        color: #f7a600;
      }
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.top-home {
  z-index: 11;
  width: 100%;
  margin-top: 100px;
  position: absolute;
  @include media-breakpoint-down(sm) {
    margin-top: 35px;
  }
}

.top-home-left {
  .navbar-brand {
    padding-top: 0 !important;
    img{
      width: 100%;
      max-width: inherit;
    }
  }
  @include media-breakpoint-down(md) {
    a {
      display: block;
      margin: auto;
      img {
        display: block;
        margin: auto;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    a {
      img {
        max-width: 120px;
      }
    }
  }
}

.top-home-center {
  position: relative;
  width: 272px;
  height: 16px;
  padding-top: 20px;
  color: #ffffff;
  font-family: Staatliches;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-transform: uppercase;

  h1 {
    font-size: 20px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 9px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-top: 9px;
  }
  h1 {
    @include media-breakpoint-down(md) {
      margin-top: 3px !important;
      font-size: 12px;
      display: block;
      line-height: 1.3;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      font-size: 16px;
    }

  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 2px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      content: initial;
    }
  }
  @include media-breakpoint-down(md) {
    h1 {
      margin-top: 23px;
      font-size: 12px;
      display: block;
    }
  }
}

.top-home-right {
  position: relative;
  text-align: center;
  height: 16px;
  width: 100%;
  padding-top: 44px;
  padding-left: 0;
  padding-right: 0;
  color: #ffffff;
  font-family: Staatliches;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    padding-top: 18px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 38px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 2px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      content: initial;
    }
  }
  ul {
    width: 100%;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      background-color: #fff;
      height: 15px;
      width: 2px;
      left: 50%;
      bottom: 4px;
    }
    li {
      display: inline-block;
      width: 49%;

      @include media-breakpoint-down(md) {
        display: inline-block;
        width: 45%;
      }

      a {
        color: #000000;
        font-family: Staatliches;
        font-size: 20px;
        font-weight: 400;
        line-height: 10px;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
        @include media-breakpoint-down(sm) {
          color: #fff;
          letter-spacing: 1px;
        }
        &:hover {
          color: #f7a600 !important;
        }
      }
    }
  }
}

.title-page {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.list-post {
  margin: 50px 0 0 0;
  @include media-breakpoint-down(md) {
    margin: 20px 0 0 0;
  }
  .item {
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
    .actu-content {
      color: #000000;
      .bl-actu-img {
        position: relative;
        overflow: hidden;
        display: block;
        .actu-img {
          height: 216px;
          display: block;
          transition: all 0.5s ease-in-out;
          transform: scale(1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          @include media-breakpoint-down(md) {
            height: 100px;
          }
          @include media-breakpoint-down(xs) {
            height: 200px;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .actu-title {
        font-family: Staatliches;
        margin-top: 20px;
        font-size: 22px;
        font-weight: 400;
        line-height: 22px;
        display: block;
        @include media-breakpoint-down(md) {
          margin-top: 18px;
          line-height: 23px;
        }
        .actu-cat {
          color: #f7a600;
          font-family: Staatliches;
          letter-spacing: 1px;
          font-size: 22px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .actu-text {
        font-family: "Archivo";
        font-weight: 400;
        line-height: 17px;
        display: block;
        margin-top: 6px;
      }
      .actu-date {
        color: #808080;
        margin-top: 10px;
        font-family: "Archivo";
        font-size: 10px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
        display: block;
      }
    }
  }
  .menu-cat {
    color: #808080;
    font-family: Staatliches;
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    width: 100%;
    padding: 0px 0 50px 0;
    @include media-breakpoint-down(lg) {
      font-size: 34px;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
      text-align: center;
    }
    &.cat-matiere {
      font-size: 28px;
      li {
        a {
          &:after {
            content: "";
            height: 30px;
          }
        }
      }
    }
    li {
      display: inline-block;
      a {
        position: relative;
        color: #808080;
        padding-right: 30px;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
        }
        @include media-breakpoint-down(md) {
          font-size: 30px;
          text-align: center;
          margin-bottom: 30px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }
        &.active,
        &:hover {
          color: #000000;
        }
        &:after {
          content: "";
          background: #808080;
          height: 40px;
          width: 4px;
          display: block;
          position: absolute;
          right: 10px;
          top: 5px;
          @include media-breakpoint-down(lg) {
            height: 25px;
            width: 3px;
          }
          @include media-breakpoint-down(md) {
            height: 25px;
          }
          @include media-breakpoint-down(sm) {
            height: 17px;
          }
        }
      }
      &:last-child {
        a {
          &:after {
            content: none;
          }
        }
      }
    }
  }
  .all-cat {
    height: 20px;
    color: #808080;
    font-family: Staatliches;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 13px;
    display: block;
    text-align: right;
    position: relative;
    margin-right: 10px;
    @include media-breakpoint-down(lg) {
      font-size: 17px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      text-align: center;
      display: inline-block;
      margin-right: 0;
    }
    &:after {
      content: "";
      background: #808080;
      height: 15px;
      width: 3px;
      display: block;
      position: absolute;
      right: -10px;
      top: 6px;
      @include media-breakpoint-down(md) {
        content: none;
      }
    }
    &.active,
    &:hover {
      color: #000000;
    }
  }
}

#tpl_conversation {
  .list-post {
    .item {
      .actu-content {
        color: #000000;
        margin-bottom: 20px;
        border-bottom: 1px solid #000;
      }
    }
  }
}

#matiere {
  .actu-img {
    height: 300px;
  }
  .actu-title {
    font-size: 40px;
    line-height: 40px;
    .actu-cat {
      font-size: 22px;
    }
  }
  .actu-date {
    margin-top: 30px;
  }
}

#slider-list-post{
  .actu-img{
    height: 216px;
  }
}

#slider-actu-bottom,
#slider-list-post {
  background-color: #323232;
  padding: 45px 0 0 0;
  position: relative;
  height: 430px;
  overflow: hidden;
  h3 {
    color: #fcfff7;
    font-family: Staatliches;
    font-size: 20px;
    font-weight: 400;
    line-height: 17px;
    text-transform: uppercase;
    padding-bottom: 37px;
  }
  .actu-title {
    color: #fff;
    font-family: Staatliches;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    span {
      color: #808080;
      font-size: 16px;
    }
  }
}

.home {
  .side-buttons {
    top: 55px;
  }
}

#chiffres {
  background-size: cover;
  padding-bottom: 40px;
  background-position: center;
  overflow: hidden;
  h2 {
    color: #f7a600;
    font-family: Staatliches;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    line-height: 30px;
    margin-top: 40px;
    margin-bottom: -30px;
  }
  h3 {
    color: #ffffff;
    font-family: Staatliches;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
  }
  .infographie {
    display: table;
    margin: auto;
    img {
      margin: 25px auto;
      display: block;
    }
  }
  .link-chiffre {
    text-align: center;
    margin-top: 30px;
    .lien_dr {
      padding: 2px 14px;
    }
  }
}

.bt-ct {
  margin: 20px auto;
  display: table;
}

#selection {
  .bl-actu-img {
    height: 301px;
    .actu-img {
      height: 301px;
    }
  }
}

#communaute {
  background-color: #eaeaea;
  padding: 45px 0;
  overflow: hidden;
  h2 {
    position: relative;
    color: #000000;
    font-family: Staatliches;
    font-size: 90px;
    line-height: 90px;
    margin-bottom: 5px;
    font-weight: 400;
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 26px;
      padding: 10px 0 10px 0;
    }
    &:before {
      content: "";
      width: 78px;
      height: 79px;
      display: inline-block;
      margin-right: 30px;
      background-image: url(../img/communaute.png);
      background-size: cover;
      @include media-breakpoint-down(sm) {
        width: 50px;
        height: 50px;
        margin-right: 5px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .sfsiplus_norm_row {
    margin-top: 11px;
    .sficn {
      img {
        height: 32px !important;
        width: 32px !important;
      }
    }
  }
}
