/*
|-----------------------
| 	     HEADER
|-----------------------
|
*/

/* reseau sociaux */
.rs-header {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  a {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-top: 23px;
    margin-right: 12px;
    &.in-rs {
      background-image: url('../img/in-rs.svg');
    }
    &.fb-rs {
      background-image: url('../img/fb-rs.svg');
    }
  }
}

/* logo */
.logo-block {
  text-align: center;
}

/* login */
.ico-login {
  text-align: right;
  margin-top: 23px;
  display: block;
  position: relative;
  width: 167px;
  float: right;
  &:before {
    content: "";
    background-image: url(../img/ico-login.svg);
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    left: 0;
  }
  span {
    color: #000000;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 33px;
    padding-top: 8px;
    display: block;
  }
}

/* menu */
.navbar-nav {
  text-align: center;
  margin: 0 auto;
  li {
    padding: 25px 3px 23px 3px;
    background: #000;
    line-height: 32px;
    transition: all 0.2s ease-in-out;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    a {
      color: #fff;
      font-family: Staatliches;
      font-size: 24px;
      font-weight: 400;
      padding: 0 30px;
      text-transform: uppercase;
      position: relative;
      &:before {
        //content: "";
        background-color: #f7a600;
        display: block;
      }
      &:hover {

      }
    }
  }
}

.cd-header2 {
  .navbar-nav {
    li {
      &:hover,
      &.current-menu-item,
      &.current-menu-parent {
        background: #f7a600;
      }
    }
  }
}



  .swiper-prev {
    position: absolute;
    top: 50%;
    left: 60px;
    z-index: 5;

    background-image: none;

    cursor: pointer;

    border-radius: 50%;
    width: 25px;
    height: 25px;

    background-color: rgba($white, .4);
    box-shadow: 2px 2px 5px 0 rgba($black, .5);

    &:before {
      content: "";
      background-image: none;

      width: 8px;
      height: 2px;

      border-radius: 10px;

      background-color: $black;

      transform: rotate(45deg);
      transform-origin: center left;

      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 4px);

      margin-top: -1px;
    }

    &:after {
      content: "";
      background-image: none;

      width: 8px;
      height: 2px;

      border-radius: 10px;

      background-color: $black;

      transform: rotate(-45deg);
      transform-origin: center left;

      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 4px);
    }
  }

  .swiper-next {
    position: absolute;
    top: 50%;
    right: 60px;
    z-index: 5;

    background-image: none;

    cursor: pointer;

    border-radius: 50%;
    width: 25px;
    height: 25px;

    background-color: rgba($white, .4);
    box-shadow: 2px 2px 5px 0 rgba($black, .5);

    &:before {
      content: "";
      background-image: none;

      width: 8px;
      height: 2px;

      border-radius: 10px;

      background-color: $black;

      transform: rotate(45deg);
      transform-origin: center right;

      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
    }

    &:after {
      content: "";
      background-image: none;

      width: 8px;
      height: 2px;

      border-radius: 10px;

      background-color: $black;

      transform: rotate(-45deg);
      transform-origin: center right;

      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
    }
  }