/*
|-----------------------
| 	     BUTTONS
|-----------------------
|
*/

.btn{
  background-color: #fff;
  border-radius: 10px 10px 10px 0;
  border: 1px solid #f7a600;
  color: #010101;
  font-family: Archivo;
  font-size: 10px;
  font-weight: 700;
  line-height: 17px;
  &:hover{
    border: 1px solid #f7a600;
    color: #fff;
    background-color: #f7a600;
  }
}

.more-post{
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
  a{
    padding: 3px 15px;
  }
}